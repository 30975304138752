var comm = {
  "0": {
    "comRaiting": 1,
    "comDate": "19 мартa 2020, 15:38",
    "userName": "Ильин Корнелий Тарасович1",
    "postTheme": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "commentText": "Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate. Услугу подбора предложений \"Zaimika\" в версии для персональных компьютеров предоставляет компания ООО \"ЗАЙМИКА\", ОГРН 1207100005683, ИНН/КПП 7106088262 / 710601001. Сайт оказывает платные услуги по подбору кредитных продуктов для клиентов."
  },
  "1": {
    "comRaiting": 2,
    "comDate": "19 мартa 2020, 15:38",
    "userName": "Ильин Корнелий Тарасович2",
    "postTheme": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "commentText": "Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate. Услугу подбора предложений \"Zaimika\" в версии для персональных компьютеров предоставляет компания ООО \"ЗАЙМИКА\", ОГРН 1207100005683, ИНН/КПП 7106088262 / 710601001. Сайт оказывает платные услуги по подбору кредитных продуктов для клиентов."
  },
  "2": {
    "comRaiting": 3,
    "comDate": "19 мартa 2020, 15:38",
    "userName": "Ильин Корнелий Тарасович3",
    "postTheme": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "commentText": "Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate. Услугу подбора предложений \"Zaimika\" в версии для персональных компьютеров предоставляет компания ООО \"ЗАЙМИКА\", ОГРН 1207100005683, ИНН/КПП 7106088262 / 710601001. Сайт оказывает платные услуги по подбору кредитных продуктов для клиентов."
  }
},
testimonials = {
  "0": {
    "userName": "Ильин Корнелий Тарасович1",
    "testimonialsDate": "19 мартa 2020, 15:38",
    "testimonialsRaiting": 5,
    "testimonialsText": "Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate. Услугу подбора предложений \"Zaimika\" в версии для персональных компьютеров предоставляет компания ООО \"ЗАЙМИКА\", ОГРН 1207100005683, ИНН/КПП 7106088262 /710601001. Сайт оказывает платные услуги по подбору кредитных продуктов для клиентов, а именно предлагает клиенту список предложений кредитных учреждений, некредитных финансовый организаций, в которые клиент может обратиться с целью оформления заявки на кредитный продукт."
  },
  "1": {
    "userName": "Ильин Корнелий Тарасович2",
    "testimonialsDate": "19 мартa 2020, 15:38",
    "testimonialsRaiting": 5,
    "testimonialsText": "Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate. Услугу подбора предложений \"Zaimika\" в версии для персональных компьютеров предоставляет компания ООО \"ЗАЙМИКА\", ОГРН 1207100005683, ИНН/КПП 7106088262 /710601001. Сайт оказывает платные услуги по подбору кредитных продуктов для клиентов, а именно предлагает клиенту список предложений кредитных учреждений, некредитных финансовый организаций, в которые клиент может обратиться с целью оформления заявки на кредитный продукт."
  },
  "2": {
    "userName": "Ильин Корнелий Тарасович3",
    "testimonialsDate": "19 мартa 2020, 15:38",
    "testimonialsRaiting": 5,
    "testimonialsText": "Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate. Услугу подбора предложений \"Zaimika\" в версии для персональных компьютеров предоставляет компания ООО \"ЗАЙМИКА\", ОГРН 1207100005683, ИНН/КПП 7106088262 /710601001. Сайт оказывает платные услуги по подбору кредитных продуктов для клиентов, а именно предлагает клиенту список предложений кредитных учреждений, некредитных финансовый организаций, в которые клиент может обратиться с целью оформления заявки на кредитный продукт."
  }
}

/**
* Main validation form
* @param {form} jquery obj - Form
* @param {options} obj - object width params
*/
function validate(form, options){
    var setings = {
        errorFunction:null,
        submitFunction:null,
        highlightFunction:null,
        unhighlightFunction:null
    }
    $.extend(setings, options);

    var $form;
    if (form == '$(this)'){
        $form = form;
    } else {
        $form = $(form);
    }

    if ($form.length && $form.attr('novalidate') === undefined) {
        $form.on('submit', function(e) {
            e.preventDefault();
        });

        $form.validate({
            errorClass : 'errorText',
            focusCleanup : true,
            focusInvalid : false,
            invalidHandler: function(event, validator) {
                if(typeof(setings.errorFunction) === 'function'){
                    setings.errorFunction(form);
                }
            },
            errorPlacement: function(error, element) {
                error.appendTo( element.closest('.form_input'));
            },
            highlight: function(element, errorClass, validClass) {
                $(element).addClass('error');
                $(element).closest('.form_row').addClass('error').removeClass('valid');
                if( typeof(setings.highlightFunction) === 'function' ) {
                    setings.highlightFunction(form);
                }
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).removeClass('error');
                if($(element).closest('.form_row').is('.error')){
                    $(element).closest('.form_row').removeClass('error').addClass('valid');
                }
                if( typeof(setings.unhighlightFunction) === 'function' ) {
                    setings.unhighlightFunction(form);
                }
            },
            submitHandler: function(form) {
                if( typeof(setings.submitFunction) === 'function' ) {
                    setings.submitFunction(form);
                } else {
                    $form[0].submit();
                }
            }
        });

        $('[required]',$form).each(function(){
            $(this).rules( "add", {
                required: true,
                messages: {
                    required: "Вы пропустили"
                }
            });
        });

        if($('[type="email"]',$form).length) {
            $('[type="email"]',$form).rules( "add",
            {
                messages: {
                    email: "Невалидный email"
                 }
            });
        }

        if($('.tel-mask[required]',$form).length){
            $('.tel-mask[required]',$form).rules("add",
            {
                messages:{
                    required:"Введите номер мобильного телефона."
                }
            });
        }

        $('[type="password"]',$form).each(function(){
            if($(this).is("#re_password") == true){
                $(this).rules("add", {
                    minlength:3,
                    equalTo:"#password",
                    messages:{
                        equalTo:"Неверный пароль.",
                        minlength:"Недостаточно символов."
                    }
                });
            }
        })
    }
}


/**
* Sending form with a call popup
* @param {form} string - Form
*/
function validationCall(form){

  var thisForm = $(form);
  var formSur = thisForm.serialize();

    $.ajax({
        url : thisForm.attr('action'),
        data: formSur,
        method:'POST',
        success : function(data){
            if ( data.trim() == 'true') {
                thisForm.trigger("reset");
                $.fancybox.close();
                popNext("#call_success", "call-popup");
            }
            else {
               thisForm.trigger('reset');
            }

        }
    });

}


/**
* Sending form with a call popup
* @param {popupId} string - Id form, that we show
* @param {popupWrap} string - Name of class, for wrapping popup width form
*/
function popNext(popupId, popupWrap){

     $.fancybox.open({
        src  : popupId,
        type : '',
        opts : {
            baseClass: popupWrap || '',
            afterClose: function () {
                $('form').trigger("reset");
                clearTimeout(timer);
            }
        }
    });

    var timer = null;

    timer = setTimeout(function () {
        $('form').trigger("reset");
        $.fancybox.close();
    }, 2000);

}


/**
* Submitting the form with the file
* @param {form} string - Form
* не использовать input[type="file"] в форме и не забыть дописать форме enctype="multipart/form-data"
*/
function validationCallDocument(form){

    var thisForm = $(form);
    var formData = new FormData($(form)[0]);

    formData.append('file', thisForm.find('input[type=file]')[0].files[0]);

    $.ajax({
        url: thisForm.attr('action'),
        type: "POST",
        data: formData,
        contentType:false,
        processData:false,
        cache:false,
        success: function(response) {
            thisForm.trigger("reset");
            popNext("#call_success", "call-popup");
        }
    });

}


/**
* Submitting the form with the files
* @param {form} string - Form
* не использовать input[type="file"] в форме и не забыть дописать форме enctype="multipart/form-data"
*/
function validationCallDocuments(form){

    var thisForm = $(form);
    var formData = new FormData($(form)[0]);

    $.each(thisForm.find('input[type="file"]')[0].files, function(index, file){
        formData.append('file['+index+']', file);
    });

    $.ajax({
        url: thisForm.attr('action'),
        type: "POST",
        data: formData,
        contentType:false,
        processData:false,
        cache:false,
        success: function(response) {
            thisForm.trigger("reset");
            popNext("#call_success", "call-popup");
        }
    });

}


/**
* Mask on input(russian telephone)
*/
function Maskedinput(){
    if($('.tel-mask')){
        $('.tel-mask').mask('+9 (999) 999-99-99 ');
    }
}


/**
* Fansybox on form
*/
function fancyboxForm(){
    $('.fancybox-form').fancybox({
        baseClass: 'fancybox-form'
    })
}

function loadMoreComments(objVariable, loadMoreBtn, appendBlock) {
  var i = 0;
  function loadContent() {
    var createString = JSON.stringify(objVariable),
        parseString = JSON.parse(createString),
        objLength = Object.keys(parseString).length,
        elementsNumber= i + 2;
    if(i < objLength) {
      for(i; i < elementsNumber; i++) {
        if(i > objLength - 1) {
          $(loadMoreBtn).fadeOut(300);
          break;
        }
        var render = `
          <div class="last-com__block">
            <div class="last-com__top">
              <div class="last-com__stars" data-count="${parseString[i].comRaiting}">
                <div class="last-com__stars-imgs">
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip1)">
                      <path d="M14.8865 5.29942L10.1001 4.87545L8.19883 0.658933C8.06569 0.363689 7.6274 0.363689 7.4943 0.658933L5.59302 4.87542L0.806665 5.29942C0.471508 5.32908 0.336086 5.72721 0.588929 5.93936L4.20025 8.96926L3.14338 13.4478C3.06937 13.7614 3.424 14.0074 3.71338 13.8433L7.84656 11.4994L11.9797 13.8433C12.2692 14.0075 12.6237 13.7614 12.5497 13.4478L11.4928 8.96926L15.1042 5.93936C15.357 5.72721 15.2216 5.32908 14.8865 5.29942Z" fill="#D1E2EE"></path>
                      <path d="M8.83109 2.29111L8.1397 0.75781C8.00656 0.462566 7.56827 0.462566 7.43517 0.75781L5.53389 4.97429L0.747582 5.39829C0.412426 5.42796 0.277004 5.82609 0.529847 6.03824L4.14116 9.06814L3.0843 13.5467C3.01029 13.8603 3.36491 14.1063 3.6543 13.9422L4.2044 13.6302C5.10622 8.05485 7.64153 3.96209 8.83109 2.29111Z" fill="#AABBC6"></path>
                    </g>
                    <defs>
                      <clipPath id="clip1">
                        <rect width="15.575" height="14" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip2)">
                      <path d="M14.8865 5.29942L10.1001 4.87545L8.19883 0.658933C8.06569 0.363689 7.6274 0.363689 7.4943 0.658933L5.59302 4.87542L0.806665 5.29942C0.471508 5.32908 0.336086 5.72721 0.588929 5.93936L4.20025 8.96926L3.14338 13.4478C3.06937 13.7614 3.424 14.0074 3.71338 13.8433L7.84656 11.4994L11.9797 13.8433C12.2692 14.0075 12.6237 13.7614 12.5497 13.4478L11.4928 8.96926L15.1042 5.93936C15.357 5.72721 15.2216 5.32908 14.8865 5.29942Z" fill="#D1E2EE"></path>
                      <path d="M8.83109 2.29111L8.1397 0.75781C8.00656 0.462566 7.56827 0.462566 7.43517 0.75781L5.53389 4.97429L0.747582 5.39829C0.412426 5.42796 0.277004 5.82609 0.529847 6.03824L4.14116 9.06814L3.0843 13.5467C3.01029 13.8603 3.36491 14.1063 3.6543 13.9422L4.2044 13.6302C5.10622 8.05485 7.64153 3.96209 8.83109 2.29111Z" fill="#AABBC6"></path>
                    </g>
                    <defs>
                      <clipPath id="clip2">
                        <rect width="15.575" height="14" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip3)">
                      <path d="M14.8865 5.29942L10.1001 4.87545L8.19883 0.658933C8.06569 0.363689 7.6274 0.363689 7.4943 0.658933L5.59302 4.87542L0.806665 5.29942C0.471508 5.32908 0.336086 5.72721 0.588929 5.93936L4.20025 8.96926L3.14338 13.4478C3.06937 13.7614 3.424 14.0074 3.71338 13.8433L7.84656 11.4994L11.9797 13.8433C12.2692 14.0075 12.6237 13.7614 12.5497 13.4478L11.4928 8.96926L15.1042 5.93936C15.357 5.72721 15.2216 5.32908 14.8865 5.29942Z" fill="#D1E2EE"></path>
                      <path d="M8.83109 2.29111L8.1397 0.75781C8.00656 0.462566 7.56827 0.462566 7.43517 0.75781L5.53389 4.97429L0.747582 5.39829C0.412426 5.42796 0.277004 5.82609 0.529847 6.03824L4.14116 9.06814L3.0843 13.5467C3.01029 13.8603 3.36491 14.1063 3.6543 13.9422L4.2044 13.6302C5.10622 8.05485 7.64153 3.96209 8.83109 2.29111Z" fill="#AABBC6"></path>
                    </g>
                    <defs>
                      <clipPath id="clip3">
                        <rect width="15.575" height="14" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip4)">
                      <path d="M14.8865 5.29942L10.1001 4.87545L8.19883 0.658933C8.06569 0.363689 7.6274 0.363689 7.4943 0.658933L5.59302 4.87542L0.806665 5.29942C0.471508 5.32908 0.336086 5.72721 0.588929 5.93936L4.20025 8.96926L3.14338 13.4478C3.06937 13.7614 3.424 14.0074 3.71338 13.8433L7.84656 11.4994L11.9797 13.8433C12.2692 14.0075 12.6237 13.7614 12.5497 13.4478L11.4928 8.96926L15.1042 5.93936C15.357 5.72721 15.2216 5.32908 14.8865 5.29942Z" fill="#D1E2EE"></path>
                      <path d="M8.83109 2.29111L8.1397 0.75781C8.00656 0.462566 7.56827 0.462566 7.43517 0.75781L5.53389 4.97429L0.747582 5.39829C0.412426 5.42796 0.277004 5.82609 0.529847 6.03824L4.14116 9.06814L3.0843 13.5467C3.01029 13.8603 3.36491 14.1063 3.6543 13.9422L4.2044 13.6302C5.10622 8.05485 7.64153 3.96209 8.83109 2.29111Z" fill="#AABBC6"></path>
                    </g>
                    <defs>
                      <clipPath id="clip4">
                        <rect width="15.575" height="14" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip5)">
                      <path d="M14.8865 5.29942L10.1001 4.87545L8.19883 0.658933C8.06569 0.363689 7.6274 0.363689 7.4943 0.658933L5.59302 4.87542L0.806665 5.29942C0.471508 5.32908 0.336086 5.72721 0.588929 5.93936L4.20025 8.96926L3.14338 13.4478C3.06937 13.7614 3.424 14.0074 3.71338 13.8433L7.84656 11.4994L11.9797 13.8433C12.2692 14.0075 12.6237 13.7614 12.5497 13.4478L11.4928 8.96926L15.1042 5.93936C15.357 5.72721 15.2216 5.32908 14.8865 5.29942Z" fill="#D1E2EE"></path>
                      <path d="M8.83109 2.29111L8.1397 0.75781C8.00656 0.462566 7.56827 0.462566 7.43517 0.75781L5.53389 4.97429L0.747582 5.39829C0.412426 5.42796 0.277004 5.82609 0.529847 6.03824L4.14116 9.06814L3.0843 13.5467C3.01029 13.8603 3.36491 14.1063 3.6543 13.9422L4.2044 13.6302C5.10622 8.05485 7.64153 3.96209 8.83109 2.29111Z" fill="#AABBC6"></path>
                    </g>
                    <defs>
                      <clipPath id="clip5">
                        <rect width="15.575" height="14" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="last-com__stars-text">
                  <span class="count-active"></span>
                  <span>/5</span>
                </div>
              </div>
              <div class="last-com__date">
                <span>${parseString[i].comDate}</span>
              </div>
            </div>
            <div class="last-com__content">
              <div class="last-com__name">
                <span>${parseString[i].userName}</span>
              </div>
              <div class="last-com__link">
                <span>Обсуждение статьи: </span>
                <a href="#">${parseString[i].postTheme}</a>
              </div>
              <div class="last-com__text">
                <p>${parseString[i].commentText}</p>
              </div>
            </div>
          </div>`;
        $(appendBlock).append(render);
        getActiveStart();
      }
    }
  }

  $(document).on('click', loadMoreBtn, loadContent);
}
function loadMoreTestimonials(objVariable, loadMoreBtn, appendBlock) {
  var i = 0;
  function loadContent() {
    var createString = JSON.stringify(objVariable),
      parseString = JSON.parse(createString),
      objLength = Object.keys(parseString).length,
      elementsNumber= i + 2;
    if(i < objLength) {
      for(i; i < elementsNumber; i++) {
        if(i > objLength - 1) {
          $(loadMoreBtn).fadeOut(300);
          break;
        }
        var render = `
          <div class="last-com__block">
            <div class="last-com__top">
              <div class="last-com__name">
                <span>${parseString[i].userName}</span>
              </div>
              <div class="last-com__date">
                <span>${parseString[i].testimonialsDate}</span>
              </div>
              <div class="last-com__stars" data-count="${parseString[i].testimonialsRaiting}">
                <div class="last-com__stars-imgs">
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip1)">
                      <path
                        d="M14.8865 5.29942L10.1001 4.87545L8.19883 0.658933C8.06569 0.363689 7.6274 0.363689 7.4943 0.658933L5.59302 4.87542L0.806665 5.29942C0.471508 5.32908 0.336086 5.72721 0.588929 5.93936L4.20025 8.96926L3.14338 13.4478C3.06937 13.7614 3.424 14.0074 3.71338 13.8433L7.84656 11.4994L11.9797 13.8433C12.2692 14.0075 12.6237 13.7614 12.5497 13.4478L11.4928 8.96926L15.1042 5.93936C15.357 5.72721 15.2216 5.32908 14.8865 5.29942Z"
                        fill="#D1E2EE"></path>
                      <path
                        d="M8.83109 2.29111L8.1397 0.75781C8.00656 0.462566 7.56827 0.462566 7.43517 0.75781L5.53389 4.97429L0.747582 5.39829C0.412426 5.42796 0.277004 5.82609 0.529847 6.03824L4.14116 9.06814L3.0843 13.5467C3.01029 13.8603 3.36491 14.1063 3.6543 13.9422L4.2044 13.6302C5.10622 8.05485 7.64153 3.96209 8.83109 2.29111Z"
                        fill="#AABBC6"></path>
                    </g>
                    <defs>
                      <clipPath id="clip1">
                        <rect width="15.575" height="14" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip2)">
                      <path
                        d="M14.8865 5.29942L10.1001 4.87545L8.19883 0.658933C8.06569 0.363689 7.6274 0.363689 7.4943 0.658933L5.59302 4.87542L0.806665 5.29942C0.471508 5.32908 0.336086 5.72721 0.588929 5.93936L4.20025 8.96926L3.14338 13.4478C3.06937 13.7614 3.424 14.0074 3.71338 13.8433L7.84656 11.4994L11.9797 13.8433C12.2692 14.0075 12.6237 13.7614 12.5497 13.4478L11.4928 8.96926L15.1042 5.93936C15.357 5.72721 15.2216 5.32908 14.8865 5.29942Z"
                        fill="#D1E2EE"></path>
                      <path
                        d="M8.83109 2.29111L8.1397 0.75781C8.00656 0.462566 7.56827 0.462566 7.43517 0.75781L5.53389 4.97429L0.747582 5.39829C0.412426 5.42796 0.277004 5.82609 0.529847 6.03824L4.14116 9.06814L3.0843 13.5467C3.01029 13.8603 3.36491 14.1063 3.6543 13.9422L4.2044 13.6302C5.10622 8.05485 7.64153 3.96209 8.83109 2.29111Z"
                        fill="#AABBC6"></path>
                    </g>
                    <defs>
                      <clipPath id="clip2">
                        <rect width="15.575" height="14" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip3)">
                      <path
                        d="M14.8865 5.29942L10.1001 4.87545L8.19883 0.658933C8.06569 0.363689 7.6274 0.363689 7.4943 0.658933L5.59302 4.87542L0.806665 5.29942C0.471508 5.32908 0.336086 5.72721 0.588929 5.93936L4.20025 8.96926L3.14338 13.4478C3.06937 13.7614 3.424 14.0074 3.71338 13.8433L7.84656 11.4994L11.9797 13.8433C12.2692 14.0075 12.6237 13.7614 12.5497 13.4478L11.4928 8.96926L15.1042 5.93936C15.357 5.72721 15.2216 5.32908 14.8865 5.29942Z"
                        fill="#D1E2EE"></path>
                      <path
                        d="M8.83109 2.29111L8.1397 0.75781C8.00656 0.462566 7.56827 0.462566 7.43517 0.75781L5.53389 4.97429L0.747582 5.39829C0.412426 5.42796 0.277004 5.82609 0.529847 6.03824L4.14116 9.06814L3.0843 13.5467C3.01029 13.8603 3.36491 14.1063 3.6543 13.9422L4.2044 13.6302C5.10622 8.05485 7.64153 3.96209 8.83109 2.29111Z"
                        fill="#AABBC6"></path>
                    </g>
                    <defs>
                      <clipPath id="clip3">
                        <rect width="15.575" height="14" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip4)">
                      <path
                        d="M14.8865 5.29942L10.1001 4.87545L8.19883 0.658933C8.06569 0.363689 7.6274 0.363689 7.4943 0.658933L5.59302 4.87542L0.806665 5.29942C0.471508 5.32908 0.336086 5.72721 0.588929 5.93936L4.20025 8.96926L3.14338 13.4478C3.06937 13.7614 3.424 14.0074 3.71338 13.8433L7.84656 11.4994L11.9797 13.8433C12.2692 14.0075 12.6237 13.7614 12.5497 13.4478L11.4928 8.96926L15.1042 5.93936C15.357 5.72721 15.2216 5.32908 14.8865 5.29942Z"
                        fill="#D1E2EE"></path>
                      <path
                        d="M8.83109 2.29111L8.1397 0.75781C8.00656 0.462566 7.56827 0.462566 7.43517 0.75781L5.53389 4.97429L0.747582 5.39829C0.412426 5.42796 0.277004 5.82609 0.529847 6.03824L4.14116 9.06814L3.0843 13.5467C3.01029 13.8603 3.36491 14.1063 3.6543 13.9422L4.2044 13.6302C5.10622 8.05485 7.64153 3.96209 8.83109 2.29111Z"
                        fill="#AABBC6"></path>
                    </g>
                    <defs>
                      <clipPath id="clip4">
                        <rect width="15.575" height="14" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip5)">
                      <path
                        d="M14.8865 5.29942L10.1001 4.87545L8.19883 0.658933C8.06569 0.363689 7.6274 0.363689 7.4943 0.658933L5.59302 4.87542L0.806665 5.29942C0.471508 5.32908 0.336086 5.72721 0.588929 5.93936L4.20025 8.96926L3.14338 13.4478C3.06937 13.7614 3.424 14.0074 3.71338 13.8433L7.84656 11.4994L11.9797 13.8433C12.2692 14.0075 12.6237 13.7614 12.5497 13.4478L11.4928 8.96926L15.1042 5.93936C15.357 5.72721 15.2216 5.32908 14.8865 5.29942Z"
                        fill="#D1E2EE"></path>
                      <path
                        d="M8.83109 2.29111L8.1397 0.75781C8.00656 0.462566 7.56827 0.462566 7.43517 0.75781L5.53389 4.97429L0.747582 5.39829C0.412426 5.42796 0.277004 5.82609 0.529847 6.03824L4.14116 9.06814L3.0843 13.5467C3.01029 13.8603 3.36491 14.1063 3.6543 13.9422L4.2044 13.6302C5.10622 8.05485 7.64153 3.96209 8.83109 2.29111Z"
                        fill="#AABBC6"></path>
                    </g>
                    <defs>
                      <clipPath id="clip5">
                        <rect width="15.575" height="14" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div class="last-com__stars-text">
                  <span class="count-active"></span>
                  <span>/5</span>
                </div>
              </div>
            </div>
            <div class="last-com__content">
              <div class="last-com__text">
                <p>${parseString[i].testimonialsText}</p>
              </div>
            </div>
          </div>`;
        $(appendBlock).append(render);
        getActiveStart();
      }
    }
  }

  $(document).on('click', loadMoreBtn, loadContent);
}

$(document).ready(function(){
  validate('#comForm', {submitFunction: validationCall});

  loadMoreComments(comm, '.more-com', '.main-comments .last-com__blocks');
  loadMoreTestimonials(testimonials, '.more-testimonials-credit', '.debit-testimonials .last-com__blocks');
  loadMoreTestimonials(testimonials, '.more-testimonials-debit', '.debit-testimonials .last-com__blocks');
  loadMoreTestimonials(testimonials, '.more-testimonials-loan', '.debit-testimonials .last-com__blocks');

  Maskedinput();
  fancyboxForm();
});
